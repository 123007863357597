import PropTypes from 'prop-types';
import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';

import Giveaways from '../../components/Page/Giveaways/Giveaways';
import SEO from '../../components/Seo/Seo';
import streamerPicture from './images/saju.png';

const GiveawayPage = ({ pageContext: { intl } }) => {
  const lang = 'pl';
  const title = 'Saju Giveaway!';
  const description =
    'Wypełnij parę prostych zadań i zwiększ swoją szansę na zdobycie zjawiskowego skina na AK-471';

  const hero = {
    title,
    description,
    glemUrl: 'https://gleam.io/5n3AJ/saju-x-skinwallet-giveaway',
    glemLoadingText: 'Wczytuję...',
    glemPlaceholderText: 'Saju x Skinwallet Giveaway',
  };
  const streamer = {
    title: 'Zgarnij akacza od Saja i Skinwalleta',
    description:
      'Paweł “saju” Pawelczak - pro-gamer, komentator i promotor esportu w Polsce, którego streamy są jednymi z najbardziej popularnych na tej scenie. Oglądaj jego stream na Twitchu, wykonuj zadania i zbieraj punkty, by zyskać szansę na AK-47 | The Empress!',
    image: (
      <LazyLoadImage
        src={streamerPicture}
        alt="Saju"
        width={300}
        height={300}
      />
    ),
  };
  const marketURL =
    'https://www.skinwallet.com/market/?ref=pawelpawelczak&utm_source=partner&utm_campaign=pawelpawelczak&utm_medium=pawelpawelczak&utm_content=giveaway';

  return (
    <>
      <SEO
        lang={lang}
        title={title}
        description={description}
        pathname={intl.originalPath}
        robots="noindex, follow"
      />
      <Giveaways
        lang={lang}
        hero={hero}
        streamer={streamer}
        marketURL={marketURL}
      />
    </>
  );
};

GiveawayPage.propTypes = {
  pageContext: PropTypes.object.isRequired,
};

export default GiveawayPage;
